import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CLHbid = makeShortcode("CLHbid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Private Tender`}</h1>
    <p>{`The use of the Private Tender platform for selling has been receiving more attention in recent years it seems, whether warranted or not (at `}<CLHbid mdxType="CLHbid" />{` we would argue it is not warranted). This increase in attention, perhaps, is driven by the desire of Sellers to have a set exit date. Private tenders offer potential Buyers a one-time opportunity to bid on land that likely will not come up for sale again in their lifetime. The process is relatively simple — 3 or 4 advertisements are placed in the local newspaper with a date set for the opening of sealed tenders. Marketing costs are kept to a minimum, as often the Buyer is seen as being someone within 50 kilometres of the land (although one can never be certain) and someone within hearing distance in the local coffee shop.`}</p>
    <p>{`Often Private Tenders are administered by the Seller’s law firm. The process however, is less than transparent as I am sure we have all heard of Tenders being ‘shopped’ to either a preferred party or a lower tenderer in an attempt to ratchet up the price. Given our human nature it is very likely the Buyer submits a Tender that equates to his/her best guess at what they can ‘get the land for’ as opposed to ‘the maximum price they would pay’ for the land. We all know no one ever bids the highest price they are willing to pay on their first bid at an auction. At `}<CLHbid mdxType="CLHbid" />{` we believe the tender process compromises value and integrity in exchange for simplicity. It is not uncommon to hear of tenders submitted that state “We hereby tender X% more than the highest tender” making a mockery out of the entire tender process.`}</p>
    <h2>{`Transparency equals integrity.`}</h2>
    <p>{`At `}<CLHbid mdxType="CLHbid" />{` a bidder knows instantly whether or not they’re the current high bidder. We are fully transparent and you see all bid amounts during our auctions. If you’re the high bidder, you know you were only one click above the last bidder. Unlike a live auction, at `}<CLHbid mdxType="CLHbid" />{` bidders don’t know who else is in the game. Even better, the Seller has no access to the identity of the bidders during the process. This prevents the Seller from being able to review tenders and trying to contact the lower ones, manipulating the process. Only the name of the successful bidder is disclosed to the Seller at the close of the auction.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      